button.active {
  background-color: #555 !important;
}

a {
  text-decoration: none;
}
.inventory {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1px;
}

.inventory-item {
  grid-column: span 1;
}
/* Covert skins */
.inventory-item.covert:hover {
  background-color: #d32ce6;
}
/* Classified skins */
.inventory-item.classified:hover {
  background-color: #eb4b4b;
}
/* Restricted skins */
.inventory-item.restricted:hover {
  background-color: #d9b100;
}
/* Mil-spec skins */
.inventory-item.mil-spec:hover {
  background-color: #3eab5d;
}
/* Industrial Grade skins */
.inventory-item.industrial:hover {
  background-color: #5e98d9;
}
/* Consumer Grade skins */
.inventory-item.consumer:hover {
  background-color: #b0c3d9;
}
/* Contraband skins */
.inventory-item.contraband:hover {
  background-color: #ff8000;
}

.inventory-item h2 {
  font-size: 13px;
}


